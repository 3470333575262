import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'
import ImageOverlay from 'components/image-overlay'

const TeamPage = ({ data }) => {
  let leadership = data.leadership ? data.leadership.edges : []
  let team = data.team ? data.team.edges : []

  return (
    <Layout>
      <PageHeader 
        title="Farm Team" 
        subtitle="The best team in East Central Indiana" 
        sizes={data.headerImage.childImageSharp.fluid}
      />
        
      <div className="l-primary">
        <nav>
          <h3>Fundamentals</h3>
          <ul>
            <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
            <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
            <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
            <li>
                <Link to={'/fundamentals/what-we-grow'}>What We Grow</Link>
                <ul>
                  <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                  <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
                </ul>   
            </li>
            <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
          </ul> 
        </nav>

        <section>
          
          <p>Howell Farms is pleased to offer employment to a group of hard-working individuals who dedicate their careers to building a large, progressive family farm. We are committed to investing in a workforce based upon merit and developing a team to lead our farming operating into the future. The individuals profiled below are the faces of Howell Farms and each contributes to our thriving farm enterprise.</p>
        </section>
        
        <aside>
          <h3>Join Our Team</h3>
          <p>Howell Farms offer the prospect of rewarding careers for hardworking individuals dedicated to learning and personal advancement.</p>
          <Link to={'/careers/company-culture'} className="buttonLink--small">Learn more</Link>
        </aside>

        {leadership.length ? (
          <section className="profiles">
            <h2>Leadership</h2>
            <div className="profile-cards">
              {leadership.map((person) => (
                <ImageOverlay 
                  key={`${person.node.frontmatter.first_name} ${person.node.frontmatter.first_name}`} 
                  to={person.node.fields.slug} 
                  sizes={person.node.frontmatter.picture.childImageSharp.fluid} 
                  heading={`${person.node.frontmatter.first_name} ${person.node.frontmatter.last_name}`} 
                  description={person.node.frontmatter.title} 
                />
              ))}  
            </div>
          </section>
        ) : null}

        <section className="profiles">
          <h2>Team Members</h2>
          <div className="profile-cards">
            {team.length? team.map((person) => (
            <ImageOverlay 
              key={`${person.node.frontmatter.first_name} ${person.node.frontmatter.first_name}`}
              to={person.node.fields.slug}
              sizes={data.sustain.childImageSharp.fluid} 
              heading={`${person.node.frontmatter.first_name} ${person.node.frontmatter.last_name}`} 
              description={person.node.frontmatter.title} 
            />
            )) : <p>Profiles of farm team members coming soon.</p>} 
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    leadership: allMarkdownRemark(
      filter: { 
        fields: { 
          type: { eq: "bios"}
        },
        frontmatter: {
          classification: { eq: "leadership" }
        }
      }, 
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          fields {
            type
            slug
          }
          frontmatter {
            order
            picture {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            classification
            first_name
            last_name
            title
            title_long
            email
            phone
          }
        }
      }
    }
    team: allMarkdownRemark(
      filter: { 
        fields: { 
          type: { eq: "bios"}
        },
        frontmatter: {
          classification: { eq: "team" }
        }
      }, 
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        node {
          fields {
            type
            slug
          }
          frontmatter {
            order
            classification
            first_name
            last_name
            title
            title_long
            email
            phone
          }
        }
      }
    }
    sustain: file(relativePath: { eq: "bios/placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "farm-team-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default TeamPage